import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import { Link } from 'gatsby'
import {device, colors} from './styles/GlobalStyles'

import Arrow from "../assets/images/greenArrow.svg"
import BluetelLogo from "../assets/images/bluetel-glyph.svg"
import CloudLogo from "../assets/images/cloud.png"
import Logos from "../assets/images/softwareLogos.svg"

const CloudTransformationSection = () => {
  return (
    <Container>
      <SectionContainer>
        <ContentContainer>
          <LeftBorder />
          <TextWrapper>
            <CloudImg src={CloudLogo}/>
            <TitleH1 id='CloudTransformationSection'>
              Cloud transformation
            </TitleH1>
            <Description>
              We help to transform your technical products to the cloud, using scalable, versatile technology, to optimise growth, cost and security.
            </Description>
            <BinaryDividerText>
              01110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 
              01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 
              0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 
              01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 01100101
            </BinaryDividerText>
            <ListWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText style={{color: 'white'}}>
                  Consulting
                </ListText>
              </ListHorizontalWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Architecture and Solution Design
                </ListText>
              </ListHorizontalWrapper>
            </ListWrapper>
            <ListWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  DevOps & Security
                </ListText>
              </ListHorizontalWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Product Engineering
                </ListText>
              </ListHorizontalWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Data Architecture
                </ListText>
              </ListHorizontalWrapper>
            </ListWrapper>
            <ListWrapper>
            <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Artificial Intelligence & Machine Learning
                </ListText>
              </ListHorizontalWrapper>
            </ListWrapper>
            <NavLink to='/case-studies'>
              See our case studies
              <ArrowIcon src={Arrow} />
            </NavLink>
          </TextWrapper>
          <SoftwareLogos src={Logos}/>
        </ContentContainer>
      </SectionContainer>
    </Container>
  )
}

export default CloudTransformationSection

const SectionContainer = styled.div`
  display: flex;  
  background: linear-gradient(180deg, #0D0629 0%, #190D47 100%);
  border-radius: 100px;
  margin-top: 64px;

  @media ${device.laptop} {
    width: 1296px;
    margin: 30px 0px 50px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 41px 16px 36px 0px;

  @media ${device.laptop} {
    padding: 72px 58px 56px 0px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  @media ${device.laptop} {
    width: 864px;
  }
`

const TitleH1 = styled.h1`
  position: static;
  font-family: Raleway;
  font-weight: 700;
  font-size: 40px;
  line-height: 118%;
  color: ${colors.white};
  margin: 20px 0px 12px 0px;

  @media ${device.laptop} {
    margin: 10px 0px 16px 0px;
    font-size: 64px;
  }
 
`

const Description = styled.p`
  font-family: Raleway;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: ${colors.grey};
  margin: 0px;
  padding: 0px;

  @media ${device.laptop} {
    width: 800px;
    font-size: 24px;
  }
`

const BinaryDividerText = styled.p`
  height: 4px;
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 3px;
  line-height: 148%;
  color: ${colors.darkPurple};
  margin: 24px 0px 32px 0px;
  overflow: hidden;

  @media ${device.laptop} {
    width: 1010px;
  }
`

const ListImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-right: 20px;

  @media ${device.laptop}{
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
`

const ListText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: ${colors.grey};
  text-align: left;

  @media ${device.laptop} {
    font-size: 21px;
  }
`

const ListHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  flex: none;
  margin: 0px 0px 16px 0px;

  @media ${device.laptop} {
    margin: 0px 48px 24px 0px;
  }
`

const ListWrapper =  styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
  }
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 148%;
  text-decoration-line: underline;
  color: ${colors.green};
  text-align: left;
  margin-top: 20px;

  @media ${device.laptop} {
    margin-top: 30px;
    font-size: 20px;
  }
`
  
const ArrowIcon = styled.img`
  width: 18.75px;
  height: 17.5px;
  margin: 0 0 0 17px;

  @media ${device.laptop}{
    width: 22.5px;
    height: 21px;
  }
`

const CloudImg = styled.img`
  margin: -10px 0px 0px -12px;
  width: 75px;
  height: 60px;
  filter: drop-shadow(0px 4px 48px #0066FF);

  @media ${device.laptop}{
    margin: -20px 0px 0px -15px;
    width: 126px;
    height: 96px;
  }
`

const LeftBorder = styled.div`
  margin: 70px 24px 0px 0px;
  height: 94px;
  border: 2px solid #00FFC2;

  @media ${device.laptop} {
    margin: 80px 64px 0px 0px;
    height: 141px;
  }
`

const SoftwareLogos = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    margin: 90px 0px 0px 66px;
    width: 240px;
    height: 382px;
  }
`