import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import { Link } from 'gatsby'
import {device, colors} from './styles/GlobalStyles'

import BoxesLogo from "../assets/images/boxes.png"
import BluetelLogo from "../assets/images/bluetel-glyph.svg"
import Arrow from "../assets/images/greenArrow.svg"
import CloudBg1 from "../assets/images/cloud-1.png"
import CloudBg2 from "../assets/images/cloud-2.png"

const EnablingDigitalExperiencesSection = () => {
  return (
    <>
      <BinaryBackground>
        <Container>
          <TextWrapper>
            <BoxesIcon src={BoxesLogo}/>
            <HeadingH1 id='EnablingDigitalExperiencesSection'>
              Enabling digital experiences
            </HeadingH1>
            <BorderDivider/>
              <Description>
                CMS and DXP consulting is our bread and butter. We’ve engineered and maintained websites and apps for anywhere from 100,000 to 100M visitors per year.
              </Description>
              <BinaryDividerText>
                01110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 
                01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 
                01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 
                01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 
                01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 01100101
              </BinaryDividerText>
              <ListWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo} />
                  <ListText style={{color: 'white'}}>
                    Consulting on CMS and DXP
                  </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                    <ListText>
                      Architecture and Solution Design
                    </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>
                    DevOps & Security
                  </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>
                    Product Engineering
                  </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>
                    Payment Gateway Integration
                  </ListText>
                </ListHorizontalWrapper>
              </ListWrapper>
              <NavLink to='/case-studies'>
                See our case studies
                <ArrowIcon src={Arrow} />
              </NavLink>
          </TextWrapper>
        </Container>
      </BinaryBackground>
    </>
  )
}

export default EnablingDigitalExperiencesSection

const BinaryBackground = styled.div`
  display: flex;
  background-image: url(${CloudBg2}), url(${CloudBg1});
  background-position: 0% 5%, top;
  background-repeat: repeat-x, repeat-x;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: static;
  padding: 100px 16px 108px 16px;

  @media ${device.laptop} {
    padding: 180px 0px 185px 0px;
  }
`

const HeadingH1 = styled.h1`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-size: 40px;
  font-weight: bold;
  color: ${colors.white};
  margin: -19px 0px 0px 0px;

  @media ${device.laptop} {
    margin: -30px 0px 0px 0px;
    font-size: 64px;
  }
`

const Description = styled.p`
  margin: 0px 0px 24px 0px;
  font-family: Raleway;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  color: ${colors.grey};

  @media ${device.laptop}{
    font-size: 24px;
    width: 780px;
  }
`

const ListImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-right: 20px;

  @media ${device.laptop}{
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
`

const ListText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: ${colors.grey};
  text-align: left;

  @media ${device.laptop} {
    font-size: 21px;
  }
`

const ListHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  flex: none;
  margin: 0px 0px 16px 0px;

  @media ${device.laptop}{
    margin: 0px 48px 24px 0px;
  }
`

const ListWrapper =  styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  @media ${device.laptop}{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`

const BinaryDividerText = styled.p`
  height: 4px;
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 3px;
  line-height: 148%;
  color: ${colors.darkPurple};
  margin: 0px 0px 32px 0px;
  overflow: hidden;
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 148%;
  text-decoration-line: underline;
  color: ${colors.green};
  margin-top: 10px;
  text-align: left;

  @media ${device.laptop}{
    text-align: center;
    margin-top: 20px;
  }
`
  
const ArrowIcon = styled.img`
  width: 22.5px;
  height: 21px;
  margin: 0 0 0 17px;
`

const BoxesIcon = styled.img`
  width: 120px;
  height: 120px;
  margin: 0px;

  @media ${device.laptop} {
    width: 184px;
    height: 184px;
  }
`

const BorderDivider = styled.div`
  margin: 24px 0px;
  width: 75px;
  border: 2px solid #00FFC2;
`