import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TechnicalStrategySection from '../components/TechnicalStrategySection'
import EngineeringSalesforceSection from '../components/EngineeringSalesforceSection'
import CloudTransformationSection from '../components/CloudTransformationSection'
import EnablingDigitalExperiencesSection from '../components/EnablingDigitalExperiencesSection'

type Props = {
  data: DigitalServicesPageQueryQuery;
}

const DigitalServicesLandingPage: React.FC<Props> = ({ data }: Props) => {

  return (
    <Layout>
      <SEO title="Digital Services"/>
      <TechnicalStrategySection/>
      <CloudTransformationSection/>
      <EngineeringSalesforceSection/>
      <EnablingDigitalExperiencesSection/>
    </Layout>
  )
}

export default DigitalServicesLandingPage

