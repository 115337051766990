import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import { Link } from 'gatsby'
import {colors, device} from './styles/GlobalStyles'

import Arrow from "../assets/images/greenArrow.svg"
import Flash from "../assets/images/flash.png"
import ConnectorImg from "../assets/images/connector.png"
import BgShapes1 from '../assets/images/shapes1.svg'
import BgShapes2 from '../assets/images/shapes2.svg'
import BluetelLogo from "../assets/images/bluetel-glyph.svg"

const EngineeringSalesforceSection = () => {
  return (
    <Container>
      <SectionContainer>
        <ContentContainer>
          <BgPicture src={BgShapes1}/>
          <TextWrapper>
            <ConnectorIcon src={ConnectorImg}/>
            <LightningBolt src={Flash}/>
            <TitleH1 id='EngineeringSalesforceSection'>
                Engineering Salesforce<br></br>solutions
            </TitleH1>
            <DividerImg/>
            <Description>
              We’re experts in formulating, designing, building and integrating<br></br>solutions for the Salesforce platform.
            </Description>
            <BinaryDividerText>
              01110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 
              01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 
              01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 
              01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 
              01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 01100101
            </BinaryDividerText>
            <ListWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText style={{color: 'white'}}>
                  Consulting
                </ListText>
              </ListHorizontalWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Product advisory
                </ListText>
              </ListHorizontalWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Declarative development
                </ListText>
              </ListHorizontalWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Apex, Visualforce and Lightning development
                </ListText>
              </ListHorizontalWrapper>
              <ListHorizontalWrapper>
                <ListImage src={BluetelLogo}/>
                <ListText>
                  Integration expertise 
                </ListText>
              </ListHorizontalWrapper>
            </ListWrapper>
            <NavLink to='/case-studies'>
              See our case studies
              <ArrowIcon src={Arrow} />
            </NavLink>
          </TextWrapper>
          <BgPicture2 src={BgShapes2}/>
        </ContentContainer>
      </SectionContainer>
    </Container>
  )
}

export default EngineeringSalesforceSection

const SectionContainer = styled.div`
  background: linear-gradient(180deg, #1F0D65 0%, #1D0E58 100%);
  box-shadow: 0px 4px 32px rgba(10, 4, 33, 0.6);

  @media ${device.laptop} {
    height: 737px;
    width: 1312px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 33.5px 16px 48px 16px;

  @media ${device.laptop} {
    padding: 64px 50px 0px 48px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media ${device.laptop} {
    width: 1008px;
  }
`

const TitleH1 = styled.h1`
  font-family: Raleway;
  font-weight: 700;
  font-size: 40px;
  line-height: 118%;
  color: ${colors.white};
  margin: -25px 0px 0px 0px;
  padding: 0px;

  @media ${device.laptop} {
    font-size: 64px;
    margin: -40px 0px 0px 0px;
  }
`

const Description = styled.p`
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  color: ${colors.grey};
  margin: 0px;
  padding: 0px;

  @media ${device.laptop}{
    font-size: 24px;
  }
`

const BinaryDividerText = styled.p`
  height: 4px;
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 3px;
  line-height: 148%;
  color: ${colors.darkPurple};
  margin: 20px 0px 28px 0px;
  overflow: hidden;

  @media ${device.laptop}{
    margin: 24px 0px 32px 0px;
  }
`

const ListImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-right: 20px;

  @media ${device.laptop}{
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
`

const ListText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: ${colors.grey};
  text-align: left;

  @media ${device.laptop}{
    font-size: 21px;
  }
`

const ListHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: none;
  margin: 0px 0px 16px 0px;

  @media ${device.laptop} {
    margin: 0px 48px 24px 0px;
  }
  
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  @media ${device.laptop}{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`

const NavLink = styled(Link)`
  display: flex;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 148%;
  text-decoration-line: underline;
  color: ${colors.green};
  text-align: left;
  align-items: center;
  margin: 16px 0 0 0;

  @media ${device.laptop} {
    margin: 20px 0 0 0;
    font-size: 20px;
    text-align: center;
  }
`

const LightningBolt = styled.img`
  width: 87.5px;
  height: 95px;
  margin: -18px 0 0 0; 

  @media ${device.laptop} {
    width: 175px;
    height: 190px;
    margin: -10px 0px 0px 0px;
    filter: drop-shadow(0px 4px 48px #0066FF);
  } 
`
  
const ArrowIcon = styled.img`
  width: 18.75px;
  height: 17.5px;
  margin: 0 0 0 17px;

  @media ${device.laptop}{
    width: 22.5px;
    height: 21px;
  }
`

const ConnectorIcon = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    margin: -155px 0px 0px 0px;
  }
`

const BgPicture = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    width: 165px;
    height: 139px;
    margin: 80px 0px 0px 0px;
    margin-right: -50px;
  }
  
`

const BgPicture2 = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    width: 158px;
    height: 83px;
    margin: 80px 0px 0px 0px; 
    margin-left: -70px;
  }
`

const DividerImg = styled.div`
  margin: 24px 0px;
  width: 75px;
  border: 2px solid #00FFC2;
`