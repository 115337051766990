import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import { RoughNotation } from "react-rough-notation"
import { Link } from 'gatsby'
import {device, colors} from './styles/GlobalStyles'

import Image from "../assets/images/code-bg.svg"
import Hammer from "../assets/images/hammer-wrench.png"
import BluetelLogo from "../assets/images/bluetel-glyph.svg"
import Arrow from "../assets/images/greenArrow.svg"


const TechnicalStrategySection = () => {
  return (
    <>
      <BinaryBackground>
        <Container>
          <HammerImgMobile src={Hammer} />
          <SectionContainer>
            <DividerImg/>
            <TextWrapper>
              <HeadingH1 id='TechnicalStrategySection'>
                <RoughNotation type ="highlight" show={true} color="#004CFF" animationDelay={200}>
                  <b>
                    Technical
                  </b>
                </RoughNotation>
                &nbsp;<br/>
                strategy, design & architecture
              </HeadingH1>
              <Description>
                We help you ideate, formulate, design and build a high-grade solution that addresses 
                your business or organisational challenges.
              </Description>
              <BinaryDividerText>
                01110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 
                01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 
                01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 
                01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 
                01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 
                01101100 01101001 01101110 01100101
              </BinaryDividerText>
              <ListWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText style={{color:'white'}}>
                    Advisory
                  </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                    <ListText>
                      Assessment
                    </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>
                    Technology Strategy
                  </ListText>
                </ListHorizontalWrapper>
              </ListWrapper>
              <ListWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>
                    Solution Design
                  </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>
                    Agile Coaching
                  </ListText>
                </ListHorizontalWrapper>
                <ListHorizontalWrapper>
                  <ListImage src={BluetelLogo}/>
                  <ListText>
                    Architecture
                  </ListText>
                </ListHorizontalWrapper>
              </ListWrapper>
              <NavLink to='/case-studies'>
                See our case studies
                <ArrowIcon src={Arrow} />
              </NavLink>
            </TextWrapper>
            <HammerImgDesktop src={Hammer}/>
          </SectionContainer>
        </Container>
      </BinaryBackground>
    </>
  )
}

export default TechnicalStrategySection

const SectionContainer = styled.div`
  display: flex;
  color: ${colors.white};
  padding: 0px 0px 55px 0px;

  @media ${device.laptop} {
    padding: 68px 105px 14px 60px;
  }
`

const BinaryBackground = styled.div`
  display: flex;
  background-image: url(${Image});
  background-position: top, bottom;
  background-origin: border-box;
  background-repeat: repeat-x;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 24px;

  @media ${device.laptop}{
    width: 865px;
    margin-left: 64px;
  }
`

const DividerImg = styled.div`
  border: 2px solid #00FFC2;
  height: 141px;
`

const HammerImgMobile = styled.img`
  width: 90px;
  height: 90px;
  margin: 10px 0 0 -25px;

  @media ${device.laptop} {
    display: none;
  }
`

const HammerImgDesktop = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    margin: -32px 0 0 18px;
    width: 192px;
    height: 192px;
    filter: drop-shadow(0px 4px 48px #0066FF);
  }
`

const HeadingH1 = styled.h1`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-size: 40px;
  font-weight: bold;
  margin: 0px;
  b { 
    font-family: "Raleway";
    font-style: normal;
    font-size: 40px;
    font-weight: bold;
  }

  @media ${device.laptop} {
    width: 760px;
    font-size: 64px;
    b { 
      font-family: "Raleway";
      font-style: normal;
      font-size: 64px;
      font-weight: bold;
    }
    br {
      display: none;
    }
  }
`

const Description = styled.p`
  margin: 16px 0px 28px 0px;
  font-family: Raleway;
  font-weight: 500;
  font-style: normal;
  font-size: 21px;
  line-height: 140%;
  color: ${colors.grey};
 
  @media ${device.laptop} {
    width: 760px;
    font-size: 24px;
    margin: 16px 0px 24px 0px;
  }
`

const ListImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px 20px 0 0;

  @media ${device.laptop} {
    width: 24px;
    height: 24px;
    margin: 0 24px 0 0;
  }
`

const ListText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: ${colors.grey};
  text-align: left;

  @media ${device.laptop} {
    font-size: 21px;
  }
`

const ListHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  margin: 0 0 16px -16px;

  @media ${device.laptop} {
    margin: 0 48px 24px 0;
  }
`

const ListWrapper =  styled.div`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
  }
`

const BinaryDividerText = styled.p`
  height: 4px;
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 3px;
  line-height: 148%;
  color: ${colors.darkPurple};
  margin: 0px 0px 24px -24px;
  overflow: hidden;

  @media ${device.laptop} {
    margin: 0px 0px 32px 0px;
  }
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 148%;
  text-decoration-line: underline;
  color: ${colors.green};
  align-items: center;
  margin: 12px 0 0 -24px;

  @media ${device.laptop} {
    margin: 20px 0 0 0;
    font-size: 20px;
  }
`
  
const ArrowIcon = styled.img`
  width: 18.75px;
  height: 17.5px;
  margin: 0 0 0 17px;

  @media ${device.laptop} {
    width: 22.5px;
    height: 21px;
  }
`